<template>
  <div class="px-4" id="createEvent">
    <div class="mb-2">
      <div>
        <nav class="sm:hidden" aria-label="Back">
          <router-link to="/events" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Zurück
          </router-link>
        </nav>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/events" class="text-sm font-medium text-gray-500 hover:text-gray-700">Events</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Event erstellen</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Neues Event erstellen</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">

        </div>
      </div>
    </div>
    <div class="p-1">
      <form @submit.prevent="publishEvent()">
        <div class="bg-white rounded p-6 shadow">
          <div class="border-b border-gray-200 pb-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Eventbeschreibung</h3>
          </div>
          <div class="relative">
            <label for="title" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Titel</label>
            <input v-model="title" maxlength="40" type="text" name="title" id="title" required class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Titel (max. 40 Zeichen)" />
          </div>
          <div class="relative mt-4">
            <label for="subTitle" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Subtitel</label>
            <input v-model="subTitle" maxlength="40" type="text" name="subTitle" id="subTitle" required class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Subtitel (max. 40 Zeichen)" />
          </div>
          <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
            <div class="sm:col-span-3">
              <div class="relative">
                <label for="ticketDescription" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung & Informationen</label>
                <textarea v-model="description" rows="12"  name="ticketDescription" id="ticketDescription" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Beschreibung & Informationen"></textarea>
              </div>
            </div>
            <div class="sm:col-span-3">
              <div class="relative block h-100 w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <img class="rounded h-100 w-full" style="position: absolute; opacity: 0.2; z-index: 0; top: 0; left: 0;" :src="config.projectURL + 'assets/images/events/templates/' + titleImageTemplate" v-if="titleImageTemplate">
                <div class="relative z10">
                  <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                  </svg>
                  <span class="mt-2 block text-sm font-semibold text-gray-900">Titelbild auswählen</span>
                  <span class="block text-sm font-thin text-gray-500">PNG oder JPEG (1200 x 640px)</span>
                  <div class="mt-2">
                    <cropper-upload @cropped="c => {this.titleImage = c; this.titleImageTemplate = false}" :ratio="1.875" ref="cropper">
                      <button type="button" class="rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">
                        Eigene Datei hochladen
                      </button>
                    </cropper-upload>
                    <div class="relative">
                      <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300" />
                      </div>
                      <div class="relative flex justify-center">
                        <span class="bg-white px-2 text-sm text-gray-500">oder</span>
                      </div>
                    </div>
                    <button @click="imageTemplatesModal = true" type="button" class="rounded bg-gray-700 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500">
                      Aus Vorlagen auswählen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
            <div class="sm:col-span-3">
              <div class="relative">
                <label for="category" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Event Kategorie</label>
                <select v-model="category" name="category" id="category" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                  <option value="" disabled>
                    Kategorie auswählen
                  </option>
                  <option value="Club">
                    Club
                  </option>
                  <option value="Rave">
                    Rave
                  </option>
                  <option value="Konzert">
                    Konzert
                  </option>
                  <option value="Straßenfest">
                    Straßenfest
                  </option>
                  <option value="Festival">
                    Festival
                  </option>
                </select>
              </div>
              <!--
              <div class="relative mt-6">
                <label for="barMenu" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Getränke- / Barkarte auswählen</label>
                <select v-model="barMenu" name="barMenu" id="barMenu" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                  <option :value="null">
                    Keine
                  </option>
                  <option :value="menu.id" v-for="menu in barMenus" :key="menu.id">
                    {{ menu.name }}
                  </option>
                </select>
              </div>
              -->
            </div>
            <div class="sm:col-span-3">
              <div class="relative">
                <label for="labels" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Eigenschaften</label>
                <select v-model="labels" name="labels" id="labels" multiple class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                  <option :value="citems.id" v-for="citems in labelOptions" :key="citems.id">
                    {{ citems.emoji }} {{ citems.title }}
                  </option>
                </select>
              </div>
              <p class="text-grey-300 text-xs">Mehrfachauswahl möglich: (Strg / Command) gedrückt halten.</p>
            </div>
          </div>
        </div>
        <div class="bg-white rounded p-6 shadow mt-4">
          <div class="border-b border-gray-200 pb-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Zeit & Ort</h3>
            <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
              <div class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span class="text-gray-500 sm:text-sm">
                      <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                    </span>
                  </div>
                  <label for="startTime" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Event-Start</label>
                  <input v-model="startTime" required type="datetime-local" name="startTime" id="startTime" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span class="text-gray-500 sm:text-sm">
                        <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                      </span>
                  </div>
                  <label for="endTime" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Event-Ende</label>
                  <input v-model="endTime" required type="datetime-local" name="endTime" id="endTime" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
              <div class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span class="text-gray-500 sm:text-sm">
                        <MapPinIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                      </span>
                  </div>
                  <label for="location" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">In welcher Stadt findet das Event statt?</label>
                  <SimpleTypeahead
                      id="city_search"
                      placeholder="Suche nache Städten"
                      :items="cities"
                      :min-input-length="1"
                      :item-projection="item => {return item.name}"
                      @select-item="item => {this.city = item}"
                      @on-input="loadCities"
                      required
                      class="block w-full rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p class="text-grey-300 text-xs">Bitte wähle eine Stadt aus den vorgeschlagenden Ergebnissen aus.</p>
              </div>
              <div class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="relative mt-2 rounded-md shadow-sm">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span class="text-gray-500 sm:text-sm">
                        <MapPinIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                      </span>
                    </div>
                    <label for="location" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Location (Komplette Adresse)</label>
                    <input v-model="location" required type="text" name="location" id="location" class="block w-full rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Musterclub, Mustermannstr. 23, 12345 Musterstadt"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white rounded p-6 shadow mt-4">
          <div class="border-b border-gray-200 pb-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Zusätzliche Einstellungen</h3>
          </div>
          <!--
          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex flex-grow flex-col">
              <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>AppPay?</SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">Zahlungen per App auf dem Event ermöglichen.</SwitchDescription>
            </span>
            <Switch v-model="eventlyPay" :class="[eventlyPay ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
          <span :class="[eventlyPay ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
              <span :class="[eventlyPay ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span :class="[eventlyPay ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>            </Switch>
          </SwitchGroup>
          -->
          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex flex-grow flex-col">
              <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>Muttizettel?</SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">Muttizettel für das Event aktivieren.</SwitchDescription>
            </span>
            <Switch v-model="muttizettel" :class="[muttizettel ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
              <span :class="[muttizettel ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[muttizettel ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[muttizettel ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
            </Switch>
          </SwitchGroup>
          <SwitchGroup as="div" class="mt-4 flex items-center justify-between">
            <span class="flex flex-grow flex-col">
              <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>Abendkasse?</SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">Bei dem Event gibt es eine Abendkasse, bei der noch Tickets erworben werden können.</SwitchDescription>
            </span>
            <Switch v-model="abendkasse" :class="[abendkasse ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
              <span :class="[abendkasse ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[abendkasse ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[abendkasse ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
            </Switch>
          </SwitchGroup>
          <!--
          <SwitchGroup as="div" class="mt-4 flex items-center justify-between">
            <span class="flex flex-grow flex-col">
              <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>Digitale Gaderobe?</SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">Digtiale Gaderobe für das Event aktivieren.</SwitchDescription>
            </span>
            <Switch v-model="gaderobe" :class="[gaderobe ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
              <span :class="[gaderobe ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[gaderobe ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[gaderobe ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
            </Switch>
          </SwitchGroup>
          <div class="relative mt-4 rounded-md shadow-sm" v-if="gaderobe">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">€</span>
            </div>
            <label for="gaderobePrice" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Preis für die Gaderobe</label>
            <input v-model="gaderobePrice" step="0.01" type="number" name="gaderobePrice" id="gaderobePrice" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="gaderobePrice-currency" />
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span class="text-gray-500 sm:text-sm" id="fee-currency">EUR</span>
            </div>
          </div>
          -->
        </div>
        <!--
        <div class="bg-white rounded p-6 shadow mt-4">
          <div class="border-b border-gray-200 pb-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Lounges</h3>
            <div class="mt-4">
              <p>Klicke auf die jeweilgen Lounges, um diese zu aktivieren:</p>
              <lounge-select :lounge-templates="loungeTemplates" :lounges="lounges" @add-lounge="addLounge" @remove-lounge="removeLounge" />
            </div>
          </div>
        </div>
        -->
        <div class="bg-white rounded p-6 shadow mt-4">
          <div class="border-b border-gray-200 pb-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Tickets</h3>
          </div>
          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex flex-grow flex-col">
              <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>Ticketverkauf:</SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">Sind Tickets für dieses Event erforderlich?</SwitchDescription>
            </span>
            <Switch v-model="ticketSale" :class="[ticketSale ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
              <span :class="[ticketSale ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[ticketSale ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[ticketSale ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
            </Switch>
          </SwitchGroup>
          <div v-if="ticketSale" class="mt-4">
            <div class="relative rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm">MwSt</span>
              </div>
              <label for="ticketTaxes" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Steuersatz für Tickets</label>
              <input v-model="ticketTaxes" step="1" type="number" name="ticketTaxes" id="ticketTaxes" class="block w-full rounded-md border-0 py-1.5 pl-20 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="19%" aria-describedby="ticketTaxes-currency" />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-500 sm:text-sm" id="ticketTaxes-currency">%</span>
              </div>
            </div>
            <SwitchGroup as="div" class="flex items-center justify-between mt-4">
              <span class="flex flex-grow flex-col">
                <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>Ticketversicherung?</SwitchLabel>
                <SwitchDescription as="span" class="text-sm text-gray-500">Haben App-Nutzer die möglichkeit ihre Tickets kostenpflichtig zu versichern?</SwitchDescription>
              </span>
              <Switch v-model="ticketInsurance" :class="[ticketInsurance ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
              <span :class="[ticketInsurance ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[ticketInsurance ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[ticketInsurance ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
            </SwitchGroup>
            <div class="relative mt-4 rounded-md shadow-sm" v-if="ticketInsurance">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm">€</span>
              </div>
              <label for="ticketInsurancePrice" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Preis für die Ticket-Versicherung</label>
              <input v-model="ticketInsurancePrice" step="0.01" type="number" name="ticketInsurancePrice" id="ticketInsurancePrice" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="ticketInsurancePrice-currency" />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-500 sm:text-sm" id="ticketInsurancePrice-currency">EUR</span>
              </div>
            </div>
            <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex flex-grow flex-col">
              <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>Ticketupgrades?</SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">Haben App-Nutzer die möglichkeit ihre Tickets kostenpflichtig upzugraden?</SwitchDescription>
            </span>
              <Switch v-model="ticketUpgrades" :class="[ticketUpgrades ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
              <span :class="[ticketUpgrades ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[ticketUpgrades ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[ticketUpgrades ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
            </SwitchGroup>
            <div v-if="ticketItems == '' && (ticketSaleLink == null || ticketSaleLink == '')" class="mt-8 relative block h-100 w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
              </svg>
              <span class="mt-2 block text-sm font-semibold text-gray-900">Tickets anlegen</span>
              <span class="block text-sm font-thin text-gray-500">Verkaufe direkt über unsere Plattform Tickets für dein Event<br> oder hinterlege deinen aktuellen Ticketshop.</span>
              <div class="mt-2">
                <button @click="createTicketModal = true;" type="button" class="rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">
                  Ticket erstellen
                </button>
                <div class="relative">
                  <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300" />
                  </div>
                  <div class="relative flex justify-center">
                    <span class="bg-white px-2 text-sm text-gray-500">oder</span>
                  </div>
                </div>
                <button @click="linkedTicketshopModal = true;" type="button" class="rounded bg-gray-700 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500">
                  Ticketshop verlinken
                </button>
              </div>
            </div>
            <div v-else>
              <div class="mt-8">
                <div class="border-b border-gray-200 pb-2">
                  <h5 class="text-base font-semibold leading-6 text-gray-900">Aktuelle Ticket Varianten:</h5>
                </div>
                <ul role="list" class="divide-y divide-gray-100">
                  <ticketItems v-for="titems in ticketItems" :ticket-items="titems" :key="titems.id" @update="getDraftTicketItems()" />
                </ul>
              </div>
              <div>
                <div v-if="ticketItems != ''">
                  <div class="mt-4 relative block h-100 w-full rounded-lg border-2 border-dashed border-gray-300 p-8 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
                    </svg>
                    <span class="mt-2 block text-sm font-semibold text-gray-900">Weiteres Ticket erstellen</span>
                    <span class="block text-sm font-thin text-gray-500">Du kannst belibig viele unterschiedliche Ticket-Varianten erstellen.</span>
                    <div class="mt-2">
                      <button @click="createTicketModal = true;" type="button" class="rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">
                        Ticket erstellen
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="mt-8 divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:gap-px sm:divide-y-0">
                    <div class="group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                      <div>
                        <span class="inline-flex rounded-lg p-3 bg-blue-100 ring-4 ring-white">
                          <TicketIcon class="h-6 w-6 text-blue-700" aria-hidden="true" />
                        </span>
                      </div>
                      <div class="mt-8">
                        <h3 class="text-base font-semibold leading-6 text-gray-900">
                          <a class="focus:outline-none">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute" aria-hidden="true" />
                            Ticketshop wurde verlinkt
                          </a>
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">{{ ticketSaleLink }}</p>
                        <button type="button" class="mt-4 rounded-md bg-gray-700 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" @click="linkedTicketshopModal = true;">
                          Link zum Ticketshop anpassen
                        </button>
                      </div>
                      <a :href="ticketSaleLink" target="_blank" class="absolute right-6 top-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                        <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row-reverse mt-8">
          <button @click="publicState = 1;" type="submit" class="rounded-md bg-blue-600 px-3 py-2 text-l font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
            Veröffentlichen
          </button>
          <button @click="publicState = 0;" type="submit" class="mr-2 rounded-md bg-gray-700 px-3 py-2 text-l font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
            Als Entwurf speichern
          </button>
        </div>
      </form>
    </div>
  </div>
  <TransitionRoot as="template" :show="linkedTicketshopModal">
    <Dialog as="div" class="relative z-10" @close="linkedTicketshopModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <form @submit.prevent="linkedTicketshopModal = false; saveMessage = true;">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="linkedTicketshopModal = false">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <TicketIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Ticketshop verlinken</DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Gebe die URL zum Ticketshop ein, welcher verlinkt werden soll.
                      </p>
                    </div>
                    <div>
                      <div class="relative mt-4">
                        <label for="ticketSaleLink" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">URL</label>
                        <input required type="text" v-model="ticketSaleLink" name="ticketSaleLink" id="ticketSaleLink" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="https://www.deinticketshop.de...." />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Speichern</button>
                  <button @click="ticketSaleLink = null; linkedTicketshopModal = false; saveMessage = true;" type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Verlinkung löschen</button>
                </div>
            </DialogPanel>
            </form>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <TransitionRoot as="template" :show="imageTemplatesModal">
    <Dialog as="div" class="relative z-10" @close="imageTemplatesModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="imageTemplatesModal = false">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PhotoIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Titelbild aus Galerie auswählen</DialogTitle>
                    <div class="mt-2">
                      <div class="grid-12">
                        <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-01.jpg')">
                          <img src="assets/images/events/templates/template-01.jpg" class="rounded cursor-pointer w-full">
                        </div>
                        <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-02.jpg')">
                          <img src="assets/images/events/templates/template-02.jpg" class="rounded cursor-pointer w-full">
                        </div>
                        <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-03.jpg')">
                          <img src="assets/images/events/templates/template-03.jpg" class="rounded cursor-pointer w-full">
                        </div>
                        <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-04.jpg')">
                          <img src="assets/images/events/templates/template-04.jpg" class="rounded cursor-pointer w-full">
                        </div>
                        <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-05.jpg')">
                          <img src="assets/images/events/templates/template-05.jpg" class="rounded cursor-pointer w-full">
                        </div>
                        <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-06.jpg')">
                          <img src="assets/images/events/templates/template-06.jpg" class="rounded cursor-pointer w-full">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button @click="imageTemplatesModal = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                </div>
              </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <TransitionRoot as="template" :show="createTicketModal">
    <Dialog as="div" class="relative z-10" @close="createTicketModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <form @submit.prevent="createDraftTicket()">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="createTicketModal = false">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <TicketIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Ticketvariante erstellen</DialogTitle>
                    <div>
                      <div class="relative mt-4">
                        <label for="ticketType" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ticketart</label>
                        <select id="ticketType" v-model="ticketType" required class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <option value="1" selected>
                            Standard-Ticket
                          </option>
                          <option value="2">
                            Deluxe-Ticket
                          </option>
                          <option value="3">
                            Premium-Ticket
                          </option>
                        </select>
                      </div>
                      <div class="relative mt-4">
                        <label for="ticketTitle" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ticket-Titel</label>
                        <input id="ticketTitle" v-model="ticketTitle" required type="text" maxlength="20" placeholder="Ticketname (max. 20 Zeichen)" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      </div>
                      <div class="relative mt-4">
                        <label for="ticketDescription" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung & Informationen</label>
                        <textarea id="ticketDescription" v-model="ticketDescription" rows="4" placeholder="Beschreibung & Informationen" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                      </div>
                      <div class="relative mt-4">
                        <label for="badge" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Badge</label>
                        <input v-model="badge" maxlength="40" type="text" name="badge" id="badge" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Badge" />
                      </div>
                      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                            </div>
                            <label for="sellStart" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verkaufsstart</label>
                            <input v-model="sellStart" required type="datetime-local" name="sellStart" id="sellStart" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verkaufsstart" />
                          </div>
                        </div>
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                            </div>
                            <label for="sellEnd" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verkaufsende</label>
                            <input v-model="sellEnd" required type="datetime-local" name="sellEnd" id="sellEnd" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verkaufsende" />
                          </div>
                        </div>
                      </div>
                      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                        <div class="sm:col-span-3">
                          <div class="relative mt-2">
                            <label for="feeType" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ticket Gebühr</label>
                            <select v-model="feeType" id="feeType" class="px-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                              <option value="none">
                                Keine
                              </option>
                              <option value="ticket">
                                Pro Ticket
                              </option>
                              <option value="order">
                                Pro Bestellung
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="sm:col-span-3" v-if="feeType != 'none'">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">€</span>
                            </div>
                            <label for="fee" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Gebühr (inkl. MwSt.)</label>
                            <input v-model="fee" type="number" step="0.01" name="fee" id="fee" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="fee-currency" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span class="text-gray-500 sm:text-sm" id="fee-currency">EUR</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">€</span>
                            </div>
                            <label for="price" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Brutto-Endpreis (inkl. MwSt.)</label>
                            <input v-model="price" required type="number" step="0.01" name="price" id="price" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="price-currency" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
                            </div>
                          </div>
                        </div>
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <TicketIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                            </div>
                            <label for="quantity" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verfügbare Tickets</label>
                            <input v-model="quantity" required type="text" name="quantity" id="quantity" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span class="text-gray-500 sm:text-sm">Stück</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <fieldset>
                        <div class="relative flex items-start px-2 mb-8">
                          <div class="flex h-6 items-center">
                            <input  v-model="personal" id="personal" aria-describedby="comments-description" name="personal" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                          </div>
                          <div class="ml-3 text-sm leading-6">
                            <label for="personal" class="font-medium text-gray-900">Personengebunden</label>
                            <p id="comments-description" class="text-gray-500">Tickets sind personengebunden und können nicht weitergegeben werden</p>
                          </div>
                        </div>
                      </fieldset>
                      <div class="pb-4">
                        <alert class="my-4" v-if="ticketError" type="error" title="Ups! Es ist etwas schief gelaufen." :message="ticketError"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Erstellen</button>
                  <button @click="createTicketModal = false;" type="button"  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                </div>
              </DialogPanel>
            </form>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <notification class="my-4" v-if="createMessage" type="success" title="Ticket erfolgreich erstellt" message="Die Ticketvariante wurde erfolgreich erstellt."/>
  <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
</template>

<script>
/* eslint-disable */
import SimpleTypeahead from "vue3-simple-typeahead";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import CropperUpload from "@/components/images/cropperUpload";
import ticketItems from "@/components/events/ticket";
import {aSyncData} from "@/modules/main";
import LoungeSelect from "@/components/lounges/loungeSelect";
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { ChevronLeftIcon, PhotoIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon  } from '@heroicons/vue/20/solid'
import { Switch, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: "eventCreate",
  title: 'Create Event',
  components: {
    LoungeSelect,
    CropperUpload,
    SimpleTypeahead,
    ticketItems: ticketItems,
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon, MapPinIcon, Switch, PencilSquareIcon, BanknotesIcon,
    ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, PhotoIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      ticketSale: true,
      cities: [],
      city: {},
      searchString: '',
      title: '',
      subTitle: '',
      description: '',
      labels: '',
      category: 'Club',
      ticketSaleLink: null,
      eventlyPay: null,
      startTime: null,
      endTime: null,
      titleImage: null,
      titleImageTemplate: null,
      createTicketModal: false,
      linkedTicketshopModal: false,
      draftId: Math.random().toString(36).substr(2),
      ticketTitle: '',
      ticketDescription: '',
      price: 0,
      quantity: 0,
      personal: '',
      ticketItems: '',
      public: 0,
      labelOptions: [],
      imageTemplatesModal: false,
      location: '',
      sellStart: null,
      sellEnd: null,
      feeType: 'none',
      fee: 0,
      loungeTemplates: [],
      lounges: [],
      barMenus: [],
      barMenu: null,
      ticketError: '',
      eventError: '',
      muttizettel: true,
      abendkasse: true,
      ticketUpgrades: true,
      ticketType: 1,
      gaderobe: false,
      gaderobePrice: 0,
      publicState: 0,
      saveMessage: false,
      createMessage: false,
      ticketInsurance: false,
      ticketInsurancePrice: 0.99,
      ticketTaxes: 19,
      badge: ''
    }
  },
  mounted() {
    this.config.loading = false
    this.getCategoryOptionItems()
    this.getLoungeTemplates()
    this.getBarMenus()
  },
  methods: {
    validateEventCreation(){
      this.eventError = '';
      if(!this.city.id){
        this.eventError = 'Bitte wähle eine Stadt aus der Liste aus.';
      }else{
        this.eventError = '';
        return true;
      }
      return false
    },
    publishEvent(){
      if(!this.validateEventCreation()) return
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('ticketSale', this.ticketSale ? 1 : 0)
      fD.append('ticketSaleLink', this.ticketSaleLink)
      fD.append('title', this.title)
      fD.append('labels', this.labels)
      fD.append('subTitle', this.subTitle)
      fD.append('description', this.description)
      fD.append('category', this.category)
      fD.append('eventlyPay', this.eventlyPay)
      fD.append('startTime', this.startTime)
      fD.append('endTime', this.endTime)
      fD.append('city', this.city.id)
      fD.append('location', this.location)
      fD.append('public', this.publicState)
      fD.append('draftId', this.draftId)
      fD.append('barMenu', this.barMenu)
      fD.append('muttizettel', this.muttizettel)
      fD.append('abendkasse', this.abendkasse)
      fD.append('ticketUpgrades', this.ticketUpgrades)
      fD.append('gaderobe', this.gaderobe)
      fD.append('gaderobePrice', this.gaderobePrice)
      fD.append('ticketInsurance', this.ticketInsurance)
      fD.append('ticketInsurancePrice', this.ticketInsurancePrice)
      fD.append('ticketTaxes', this.ticketTaxes)
      if(this.titleImageTemplate) {
        fD.append('titleImageTemplate', this.titleImageTemplate)
      }
      else if(this.titleImage) {
        fD.append('titleImage', this.titleImage, 'titleImage')
      }
      fD.append('lounges',JSON.stringify(this.lounges))
      this.axios.post('events/createEvent', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(r => {
            console.log(r.data)
            if(r.status === 250){
              _this.$router.push('/events')
            }
          })
          .catch(e => {console.error(e)})
    },
    loadCities(event){
      const _this = this
      this.city = {}
      this.searchString = event.input
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('search', event.input)
      this.axios.post('events/cities', fD)
          .then(r => {
            if(r.status === 250 && _this.searchString === event.input) _this.cities = r.data
          })
          .catch(e => {console.error(e)})
    },
    createDraftTicket(){
      console.log('test')
      this.createMessage = false
      if (this.validateTicketCreation()){
        aSyncData('ticketcenter/createDraftTicket', {
          ticketTitle: this.ticketTitle,
          ticketDescription: this.ticketDescription,
          price: this.price,
          quantity: this.quantity,
          draftId: this.draftId,
          personal: this.personal,
          sellStart: this.sellStart,
          sellEnd: this.sellEnd,
          fee: this.fee,
          feeType: this.feeType,
          ticketType: this.ticketType,
          badge: this.badge
        })
            .then(r => {
              console.log(r)
              if(r.status === 250){
                this.ticketTitle = null;
                this.ticketDescription = null;
                this.price = null;
                this.quantity = null;
                this.personal = null;
                this.createTicketModal = false;
                this.ticketType = 1;
                this.getDraftTicketItems()
                this.createMessage = true
                this.badge = ''
              }
            })
            .catch(e => {console.error(e)})
      }
    },
    validateTicketCreation(){
      this.ticketError = '';
      if(!this.ticketTitle.length >= 1 && !this.ticketTitle.length <= 20){
        this.ticketError = 'Bitte gebe einen Ticket-Titel ein, der maximal 20 Zeichen beträgt.';
      }else if(this.sellStart == null || this.sellEnd == null){
        this.ticketError = 'Bitte gebe einen Verkaufsstart und Ende an.';
      }else if(!(this.quantity >= 0) || this.price === ''){
        this.ticketError = 'Bitte gebe einen Ticketpreis und Menge an.';
    }else{
        this.ticketError = '';
        return true;
      }
    },
    getDraftTicketItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('draftId', this.draftId)
      this.axios.post('ticketcenter/getDraftTicketItems', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.ticketItems = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getBarMenus(){
      aSyncData('barMenu/getBarMenues', {})
          .then(r => {
            if(r.status === 250){
              this.barMenus = r.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getCategoryOptionItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('events/getCategoryOptionItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.labelOptions = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getLoungeTemplates(){
      const _this = this
      aSyncData('lounges/getTemplates', {organizerId: this.config.organizerId})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              _this.loungeTemplates = r.data
            }
          })
    },
    eventImageTemplate(template){
      this.titleImageTemplate = template
      this.titleImage = false
      this.imageTemplatesModal = false
      this.$refs.cropper.reset()
    },
    addLounge(loungeId){
      if(!this.lounges.some(i => i.id === loungeId)) {
        this.loungeTemplates.forEach(i => {
          if(i.id === loungeId) this.lounges.push(i)
        })
      }
    },
    removeLounge(loungeId){
      this.lounges = this.lounges.filter(l => l.id !== loungeId)
    }
  }
}
</script>
